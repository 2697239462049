import React, { useContext, useEffect } from 'react'
import { Field } from '@antlerengineering/form-builder'
import _find from 'lodash/find'
import _get from 'lodash/get'

import { Loading } from '@antlerengineering/components'

import { useAppContext } from 'contexts/AppContext'
import useCollection from 'hooks/useCollection'

export interface IFiregridContextProps {
  getFormFields: (key: string) => Field[]
}

export const FiregridContext = React.createContext<IFiregridContextProps>({
  getFormFields: () => [],
})

export const useFiregridContext = () => useContext(FiregridContext)

export function FiregridProvider({ children }: React.PropsWithChildren<{}>) {
  const { currentUser } = useAppContext()

  const [formsState, formsDispatch] = useCollection({})
  const [listsState, listsDispatch] = useCollection({})

  useEffect(() => {
    if (currentUser) {
      formsDispatch({
        path: '/_FIRETABLE_/_FIREGRID_/forms',
        filters: [{ field: 'app', operator: '==', value: 'Fusion' }],
      })

      listsDispatch({
        path: '/_FIRETABLE_/_FIREGRID_/lists',
      })
    }
  }, [currentUser, formsDispatch, listsDispatch])

  const getFormFields = (key: string) => {
    const doc = _find(formsState.documents, ['id', key]) ?? {}
    const rawFields = _get(doc, 'fields') ?? []

    const fieldsWithLists = rawFields.map(field => {
      if (field._optionsList) {
        const match = _find(listsState.documents, ['id', field._optionsList])

        if (match && Array.isArray(match.values)) {
          const options = [
            ...(Array.isArray(field.options) ? field.options : []),
            ...match.values,
          ]
          return { ...field, options }
        }
      }

      return field
    })

    return fieldsWithLists
  }

  if (formsState.path && formsState.loading)
    return <Loading message="Loading forms" fullScreen />

  return (
    <FiregridContext.Provider value={{ getFormFields }}>
      {children}
    </FiregridContext.Provider>
  )
}
