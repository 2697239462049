import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'
import { mdiBookOpenPageVariant } from '@mdi/js'

export default function Wiki(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path d={mdiBookOpenPageVariant} />
    </SvgIcon>
  )
}
