import React, { useState } from 'react'
import useRouter from 'hooks/useRouter'
import _find from 'lodash/find'

import {
  makeStyles,
  createStyles,
  useTheme,
  useMediaQuery,
  Button,
  ButtonProps,
  Tooltip,
  IconButton,
  CircularProgress,
} from '@material-ui/core'
import AssessmentIcon from '@material-ui/icons/Assessment'

import { Modal } from '@antlerengineering/components'

import { useAppContext } from 'contexts/AppContext'

const useStyles = makeStyles(theme =>
  createStyles({
    paper: {
      maxWidth: 'none',
      height: `calc(100% - ${theme.spacing(8)}px)`,
      fontSize: 0,

      [theme.breakpoints.down('xs')]: { height: '100%' },
    },

    contentContainer: {
      fontSize: 0,
      width: '100%',
      height: '100%',

      position: 'relative',
    },

    iframe: {
      border: 0,

      margin:
        '0 calc(var(--spacing-modal) * -1) calc(var(--spacing-modal-contents) * -1)',
      width: 'calc(100% + var(--spacing-modal) * 2)',
      height: 'calc(100% + var(--spacing-modal-contents))',
    },

    loading: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',

      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
)

export default function DataStudio(props: ButtonProps) {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const { dataStudio } = useAppContext()
  const { location } = useRouter()

  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(true)

  const handleClose = () => {
    setOpen(false)
    setLoading(true)
  }

  const config = _find(dataStudio, x =>
    x.fusionSection?.includes(location.pathname)
  )

  if (!config) return null

  return (
    <>
      {isMobile ? (
        <Tooltip title="Analytics">
          <IconButton
            onClick={() => setOpen(true)}
            aria-label="Analytics"
            color="primary"
            {...(props as any)}
          >
            <AssessmentIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Button
          startIcon={<AssessmentIcon />}
          onClick={() => setOpen(true)}
          size="large"
          {...props}
        >
          Analytics
        </Button>
      )}

      {open && (
        <Modal
          onClose={handleClose}
          title={config.title}
          fullWidth
          classes={{ paperWidthSm: classes.paper }}
          body={
            <div className={classes.contentContainer}>
              <iframe
                width="100%"
                height="100%"
                src={config.datastudioLink}
                allowFullScreen
                className={classes.iframe}
                onLoad={() => setLoading(false)}
              />
              {loading && (
                <div className={classes.loading}>
                  <CircularProgress />
                </div>
              )}
            </div>
          }
        />
      )}
    </>
  )
}
