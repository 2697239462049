import _pickBy from 'lodash/pickBy'
import _isEqual from 'lodash/isEqual'
import _find from 'lodash/find'
import { CustomClaims, IAppContextInterface } from './AppProvider'
import { db } from '../firebase'

export function shuffle(array: any[], seed: number) {
  // <-- ADDED ARGUMENT
  var m = array.length,
    t,
    i

  // While there remain elements to shuffle…
  while (m) {
    // Pick a remaining element…
    i = Math.floor(random(seed) * m--) // <-- MODIFIED LINE

    // And swap it with the current element.
    t = array[m]
    array[m] = array[i]
    array[i] = t
    ++seed // <-- ADDED LINE
  }

  return array
}

export function random(seed) {
  var x = Math.sin(seed++) * 10000
  return x - Math.floor(x)
}

export function diffChanges(
  current: { [key: string]: any },
  changed: { [key: string]: any }
) {
  return _pickBy(changed, (val, key) => !_isEqual(val, current[key]))
}

export function getFiretableConfigValues(
  document: { columns: { [key: string]: any }[] },
  field: string
) {
  return _find(document?.columns, { key: field })?.options ?? []
}

export const hasRegion = (claims: CustomClaims, cohort: string) =>
  claims.regions.includes(cohort.replace(/([0-9])/g, '')) ||
  claims.regions.includes('GL')
export const hasAnyRoles = (claims: CustomClaims, roles: string[]) =>
  roles.reduce((acc: Boolean, curr: string) => {
    if (acc) {
      return acc
    } else return claims.roles.includes(curr)
  }, false)

export function makeId(length: number) {
  let result = ''
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}
export function formatMillions(num?: number | string | null) {
  if (num === undefined || num === null) return ''
  if (typeof num === 'string') num = parseFloat(num)
  if (Number.isNaN(num)) return ''

  if (num < 1) return `${num * 1000}k`
  if (num >= 1000) return `${num / 1000}B`
  return `${num}M`
}

export const firetableUser = currentUser => {
  const {
    displayName,
    email,
    uid,
    emailVerified,
    isAnonymous,
    photoURL,
  } = currentUser
  return {
    timestamp: new Date(),
    displayName,
    email,
    uid,
    emailVerified,
    isAnonymous,
    photoURL,
  }
}

export function removeUndefined(obj: any) {
  Object.keys(obj).forEach(key => {
    // Get this value and its type
    const value = obj[key]
    const type = typeof value
    if (type === 'object') {
      // Recurse...
      removeUndefined(value)
    } else if (type === 'undefined') {
      // Undefined, remove it
      delete obj[key]
    }
  })

  return obj
}

export const createDoc = (
  collection: string,
  data: any,
  currentUser: IAppContextInterface['currentUser']
) => {
  const docData = { ...removeUndefined(data) }
  if (currentUser) docData['_ft_createdBy'] = firetableUser(currentUser)
  docData['_ft_createdAt'] = new Date()

  return db.collection(collection).add(docData)
}
export const updateDoc = (
  path: string,
  data: any,
  currentUser: IAppContextInterface['currentUser']
) => {
  const docData = { ...removeUndefined(data) }
  if (currentUser) docData['_ft_updatedBy'] = firetableUser(currentUser)
  docData['_ft_updatedAt'] = new Date()

  return db.doc(path).update(docData)
}
