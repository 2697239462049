import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'
import { mdiHandshakeOutline } from '@mdi/js'

export default function Investment(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path d={mdiHandshakeOutline} />
    </SvgIcon>
  )
}
