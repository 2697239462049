import HomeIcon from '@material-ui/icons/Home'
import ProfileIcon from '@material-ui/icons/Person'
import EmployeesIcon from '@material-ui/icons/PermIdentity'
import PortfolioIcon from '@material-ui/icons/BlurCircular'
import CohortIcon from '@material-ui/icons/Contacts'
import CoachingIcon from 'assets/icons/Coaching'
import InvestmentIcon from 'assets/icons/Investment'
import TalentNetworkIcon from '@material-ui/icons/AccountBox'
import ResourcesIcon from '@material-ui/icons/Folder'
import EventsIcon from '@material-ui/icons/Event'
import PartnershipsIcon from '@material-ui/icons/Loyalty'
import AdvisorsIcon from '@material-ui/icons/SupervisedUserCircle'
import WikiIcon from 'assets/icons/Wiki'
import ForumIcon from '@material-ui/icons/Forum'
import SettingsIcon from '@material-ui/icons/Settings'

import { CustomClaims } from 'utils/AppProvider'

export {
  HomeIcon,
  ProfileIcon,
  EmployeesIcon,
  PortfolioIcon,
  CohortIcon,
  CoachingIcon,
  InvestmentIcon,
  TalentNetworkIcon,
  EventsIcon,
  ResourcesIcon,
  PartnershipsIcon,
  AdvisorsIcon,
  WikiIcon,
  ForumIcon,
  SettingsIcon,
}

export enum routes {
  adminAuth = '/adminAuth',
  tokenAuth = '/tokenAuth',
  setLocation = '/setLocation',
  forgotPassword = '/forgotPassword',
  resetPassword = '/resetPassword',
  googleAuth = '/googleAuth',
  authLink = '/authLink',

  signIn = '/auth',
  signUp = '/signUp',

  home = '/',
  profile = '/profile',
  advisorProfile = '/advisorProfile',

  employees = '/employees',

  portfolio = '/portfolio',
  myPortfolio = '/portfolio/myPortfolio',
  globalPortfolio = '/portfolio/globalPortfolio',

  cohort = '/cohort',
  founders = '/cohort/founders',
  teams = '/cohort/teams',

  coaching = '/coaching',
  coachingMyTeams = '/coaching/myTeams',
  coachingArchive = '/coaching/archive',

  investment = '/investment',
  investmentInvestors = '/investment/investors',
  investmentFirms = '/investment/firms',

  talentNetwork = '/talentNetwork',

  resources = '/resources',
  resourcesLibrary = '/resources/library',
  resourcesProgram = '/resources/program',
  resourcesStartup = '/resources/startup',
  resourcesTech = '/resources/tech',

  events = '/events',
  // eventsCalendar = '/events/calendar',
  // eventsBookings = '/events/bookings',
  eventsGlobal = '/events/global',

  partnerships = '/perks',
  advisors = '/advisors',

  advisorsIndustryConnectors = '/advisors/industryConnectors',
  advisorsExperts = '/advisors/startupExperts',
  advisorsVenturePartners = '/advisors/venturePartners',

  settings = '/settings',
  signOut = '/signOut',
}

export type Route = {
  label: string
  showLocationInLabel?: boolean
  route: string
  Icon: typeof HomeIcon
  children?: {
    label: string
    route: string
  }[]
}

export type RouteFunctionProps = {
  userClaims?: CustomClaims
  algoliaKeys?: Record<string, string>
}
export type RouteFunction = (props: RouteFunctionProps) => Route | null

// const home: Route = () => ({
//   label: 'Home',
//   route: routes.home,
//   Icon: HomeIcon,
// })

const profile: RouteFunction = ({ userClaims }) =>
  userClaims?.roles?.includes('TEAM')
    ? {
        label: 'Profile',
        route: routes.profile,
        Icon: ProfileIcon,
      }
    : userClaims?.roles?.includes('ADVISOR')
    ? {
        label: 'Profile',
        route: routes.advisorProfile,
        Icon: ProfileIcon,
      }
    : null
// const advisorProfile: RouteFunction = () => ({
//   label: 'Profile',
//   route: routes.profile,
//   Icon: ProfileIcon,
// })

const employees: RouteFunction = ({ userClaims }) =>
  userClaims?.roles?.includes('COACH') || userClaims?.roles?.includes('TEAM')
    ? {
        label: 'Antler Team',
        route: routes.employees,
        Icon: EmployeesIcon,
      }
    : null

const portfolio: RouteFunction = ({ userClaims, algoliaKeys }) => {
  if (!algoliaKeys || Object.keys(algoliaKeys).length === 0) return null

  const children: Route['children'] = []

  // Only show full portfolio view if they have the Algolia key
  if (Object.keys(algoliaKeys).includes('portfolio-fusion'))
    children.push({
      label: 'My Portfolio',
      route: routes.myPortfolio,
    })
  // Otherwise, show basic portfolio view
  // If they are global, don’t show as this would be redundant
  if (!userClaims?.regions?.includes('GL'))
    children.push({
      label: 'Antler Portfolio',
      route: routes.globalPortfolio,
    })

  // if (children.length === 0) return null
  // If we only show one item, make that the top-level item
  if (children.length === 1) return { ...children[0], Icon: PortfolioIcon }

  return {
    label: 'Portfolio',
    route: routes.portfolio,
    Icon: PortfolioIcon,
    children,
  }
}

const cohort: RouteFunction = ({ userClaims }) =>
  userClaims?.roles?.includes('COACH') || userClaims?.roles?.includes('TEAM')
    ? {
        label: 'Cohort',
        route: routes.cohort,
        Icon: CohortIcon,

        children: [
          {
            label: 'Founders',
            route: routes.founders,
          },
          {
            label: 'Teams',
            route: routes.teams,
          },
        ],
      }
    : null

const coaching: RouteFunction = ({ userClaims }) =>
  userClaims?.roles?.includes('COACH')
    ? {
        label: 'Coaching',
        route: routes.coaching,
        Icon: CoachingIcon,

        children: [
          {
            label: 'My Teams',
            route: routes.coachingMyTeams,
          },
          {
            label: 'Archive',
            route: routes.coachingArchive,
          },
        ],
      }
    : null

const investment: RouteFunction = ({ userClaims }) =>
  userClaims?.roles?.includes('TEAM')
    ? {
        label: 'Investment',
        route: routes.investment,
        Icon: InvestmentIcon,
        children: [
          {
            label: 'Investors',
            route: routes.investmentInvestors,
          },
          {
            label: 'Firms',
            route: routes.investmentFirms,
          },
        ],
      }
    : null

const talentNetwork: RouteFunction = ({ userClaims }) =>
  userClaims?.roles?.includes('TEAM')
    ? {
        label: 'Talent Network',
        route: routes.talentNetwork,
        Icon: TalentNetworkIcon,
      }
    : null

const resources: RouteFunction = ({ userClaims }) =>
  userClaims?.roles?.includes('COACH') || userClaims?.roles?.includes('TEAM')
    ? {
        label: 'Resources',
        route: routes.resources,
        Icon: ResourcesIcon,

        children: [
          {
            label: 'Library',
            route: routes.resourcesLibrary,
          },
          {
            label: 'Program',
            route: routes.resourcesProgram,
          },
          {
            label: 'Tech',
            route: routes.resourcesTech,
          },
        ],
      }
    : null

const events: RouteFunction = () => ({
  label: 'Global Events',
  route: routes.eventsGlobal,
  Icon: EventsIcon,
})

const partnerships: RouteFunction = () => ({
  label: 'Perks',
  route: routes.partnerships,
  Icon: PartnershipsIcon,
})

const advisors: RouteFunction = () => ({
  label: 'Advisors',
  route: routes.advisors,
  Icon: AdvisorsIcon,

  children: [
    {
      label: 'Industry Connectors',
      route: routes.advisorsIndustryConnectors,
    },
    {
      label: 'Startup experts',
      route: routes.advisorsExperts,
    },
    {
      label: 'Venture Partners',
      route: routes.advisorsVenturePartners,
    },
  ],
})

const wiki: RouteFunction = ({ userClaims }) =>
  userClaims?.roles?.includes('TEAM')
    ? {
        label: 'Wiki',
        route: 'https://antler.getoutline.com/',
        externalRoute: true,
        Icon: WikiIcon,
      }
    : null

const forum: RouteFunction = ({ userClaims }) =>
  userClaims?.roles?.includes('COACH') || userClaims?.roles?.includes('TEAM')
    ? {
        label: 'Forum',
        route: 'https://forum.antler.co/',
        externalRoute: true,
        Icon: ForumIcon,
      }
    : null

// const settings:RouFunctionte =()=> ({
//   label: 'Settings',
//   route: routes.settings,
//   Icon: SettingsIcon,
// })

export const getNavItems = (props: RouteFunctionProps): Route[] => {
  const ordered = [
    // home,
    profile,
    employees,
    portfolio,
    cohort,
    coaching,
    investment,
    talentNetwork,
    resources,
    events,
    partnerships,
    advisors,
    wiki,
    // forum,
    // settings
  ]

  // Call all route functions
  const result: Route[] = []
  ordered.forEach(fn => {
    const res = fn.call(null, props)
    // Only display if not null
    if (res !== null) result.push(res)
  })

  return result
}
