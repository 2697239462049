import React, { useContext } from 'react'

export interface IAlertContextProps {
  openAlert: (props: {
    title: React.ReactNode
    message?: React.ReactNode
    body?: React.ReactNode
  }) => void
}

const DEFAULT_STATE = {
  openAlert: () => {},
}

export const AlertContext = React.createContext<IAlertContextProps>(
  DEFAULT_STATE
)
export const useAlertContext = () => useContext(AlertContext)
