import React, { useEffect, useState, useContext } from 'react'
import { auth } from '../firebase'
import useDoc from '../hooks/useDoc'
import useCollection from '../hooks/useCollection'
import * as Sentry from '@sentry/react'
import posthog from 'posthog-js'

import smartlookClient from 'smartlook-client'
import { cloudFunction, CLOUD_FUNCTIONS } from 'firebase/callables'
import { getNavItems, Route } from 'constants/routes'

interface IAppProviderProps {
  children: React.ReactNode
}
export type CustomClaims = {
  roles: string[]
  regions: string[]
}
export interface IAppContextInterface {
  algoliaKeys: Record<string, string>
  currentUser: firebase.default.User | null | undefined
  profileDocState: any | null | undefined
  updateProfileDoc: (data: any) => void
  userClaims: CustomClaims | undefined
  navItems: Route[]
  dataStudio: Record<string, any>[]
}

export const AppContext = React.createContext<IAppContextInterface>({
  algoliaKeys: {},
  currentUser: undefined,
  profileDocState: undefined,
  userClaims: undefined,
  updateProfileDoc: () => {},
  navItems: [],
  dataStudio: [],
})

export const useAppContext = () => useContext(AppContext)

const getUserType = (roles: string[]) => {
  if (roles.includes('TEAM')) return 'Team'
  if (roles.includes('ADVISOR')) return 'Advisor'
  if (roles.includes('FOUNDER')) return 'Founder'
  return 'Unknown'
}

export const AppProvider: React.FC<IAppProviderProps> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<
    null | undefined | firebase.default.User
  >()
  const [userClaims, setUserClaims] = useState<CustomClaims>()
  const [profileDocState, profileDocDispatch, updateProfileDoc] = useDoc({})
  const [algoliaKeys, setAlgoliaKeys] = useState<Record<string, string>>({})

  const [navItems, setNavItems] = useState<Route[]>([])
  useEffect(() => {
    const result = getNavItems({ userClaims, algoliaKeys })
    if (JSON.stringify(result) !== JSON.stringify(navItems)) {
      setNavItems(result)
    }
  }, [userClaims, algoliaKeys, JSON.stringify(navItems)])

  useEffect(() => {
    if (
      profileDocState.doc !== null &&
      profileDocState.doc !== undefined &&
      userClaims !== null && userClaims !== undefined &&
      currentUser !== null && currentUser !== undefined
    ) {
      posthog.identify(currentUser.uid, {
        name: currentUser.displayName,
        first_name: profileDocState.doc.firstName,
        last_name: profileDocState.doc.lastName,
        email: currentUser.email,
        Regions: profileDocState.doc.region,
        Cohort: profileDocState.doc.cohort,
        Role: getUserType(userClaims.roles),
        TrackedOut: profileDocState.doc.isTrackedOut,
        Funded: profileDocState.doc.isFunded,
        StartupIndustryFocus: profileDocState.doc.startupIndustryFocus,
        CountriesLivedIn: profileDocState.doc.countriesLivedIn,
        Education: profileDocState.doc.education,
        FounderType: profileDocState.doc.founderType,
        IndustryExperience: profileDocState.doc.industryExperience,
        IdeaToProgram: profileDocState.doc.ideaToProgram,
      })
      Sentry.setUser({
        name: currentUser.displayName,
        email: currentUser.email!,
        Regions: userClaims!.regions,
        Cohort: profileDocState.doc.cohort,
        Role: getUserType(userClaims.roles),
      })
    }
  }, [userClaims, currentUser, profileDocState.doc])

  useEffect(() => {
    auth.onAuthStateChanged(async auth => {
      setCurrentUser(auth)

      if (auth) {
        window.analytics.identify(auth.uid, {
          name: auth.displayName,
          email: auth.email,
        })

        const token = await auth.getIdTokenResult()
        setUserClaims(token?.claims as CustomClaims)

        if (!token.claims.roles)
          throw new Error('You do not have any permissions')

        if (token?.claims.roles.includes('TEAM')) {
          profileDocDispatch({ path: `employees/${auth.uid}` })
        } else if (
          token?.claims.roles.includes('COACH') ||
          token.claims.roles.includes('ADVISOR')
        ) {
          profileDocDispatch({ path: `advisors/${auth.uid}` })
        }
      }else{
        posthog.reset()
      }
    })
  }, [])

  const handleAlgoliaKeys = data => {
    setAlgoliaKeys(
      data.reduce((acc, curr) => {
        return {
          ...acc,
          ...curr.indices.reduce(
            (accIndices, currIndex) => ({
              ...accIndices,
              [currIndex]: curr.key,
            }),
            {}
          ),
        }
      }, {})
    )
  }

  useEffect(() => {
    if (profileDocState.doc) {
      if (profileDocState.doc && profileDocState.doc.algoliaKeys) {
        handleAlgoliaKeys(profileDocState.doc.algoliaKeys.keys)
      } else {
        console.log(`calling ${CLOUD_FUNCTIONS.getAlgoliaKeys}`)
        cloudFunction(
          CLOUD_FUNCTIONS.getAlgoliaKeys,
          {
            userGroup: profileDocState.path.split('/')[0],
          },
          resp => {
            handleAlgoliaKeys(resp.data.data)
          },
          error => console.log(error)
        )
      }
      console.log({ userDoc: profileDocState.doc })
    }
  }, [profileDocState.doc])

  useEffect(() => {
    if (profileDocState.doc) {
      const { firstName, lastName, cohort, email } = profileDocState.doc
      smartlookClient.identify(profileDocState.doc.id, {
        name: `${firstName} ${lastName}`,
        cohort,
        email,
      })
    }
  }, [profileDocState.doc])

  const [dataStudioState, dataStudioDispatch] = useCollection({})
  if (
    Array.isArray(userClaims?.roles) &&
    (userClaims?.roles.includes('TEAM') || userClaims?.roles.includes('COACH'))
  ) {
    if (!dataStudioState.path)
      dataStudioDispatch({
        path: 'analytics',
        filters: [{ field: 'showOnFusion', operator: '==', value: true }],
        disableErrorAlerts: true,
      })
  }
  const dataStudio = dataStudioState.documents

  return (
    <AppContext.Provider
      value={{
        algoliaKeys,
        userClaims,
        navItems,
        currentUser,
        profileDocState,
        updateProfileDoc,
        dataStudio,
      }}
    >
      {children}
    </AppContext.Provider>
  )
}

export const tokenHasValue = async (key: string, value: unknown) => {
  const user = auth.currentUser
  if (user) {
    const token = await user.getIdTokenResult()
    if (token.claims[key] && !token.claims[key].includes(value)) {
      user.getIdTokenResult(true)
    }
  }
}

export const refreshToken = () => {
  const user = auth.currentUser
  if (user) user.getIdTokenResult(true)
}
