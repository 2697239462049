import React from 'react'
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon'
import { mdiClipboardOutline } from '@mdi/js'

export default function Coaching(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d={mdiClipboardOutline} />
    </SvgIcon>
  )
}
