import React, { useEffect, useReducer, useContext } from 'react'
import firebase from 'firebase/app'
import { db } from '../firebase'

import { AppContext } from '../utils/AppProvider'
import { firetableUser } from 'utils'
import { useAlertContext } from 'contexts/AlertContext'

export enum DocActions {
  update,
  delete,
}
const documentReducer = (prevState: any, newProps: any) => {
  switch (newProps.action) {
    case DocActions.update:
      // takes data object form the dispatcher and updates doc
      db.doc(prevState.path).set({ ...newProps.data }, { merge: true })
      //prevState.ref.update({ ...newProps.data, updatedAt: new Date() });
      return { ...prevState, doc: { ...prevState.doc, ...newProps.data } }
    case DocActions.delete:
      prevState.ref.delete()
      return null
    default:
      return { ...prevState, ...newProps }
  }
}
const documentInitialState = {
  path: null,
  prevPath: null,
  doc: null,
  ref: null,
  loading: true,
  disableErrorAlerts: false,
}

const useDoc = (intialOverrides: any) => {
  const [documentState, documentDispatch] = useReducer(documentReducer, {
    ...documentInitialState,
    ...intialOverrides,
  })
  const { currentUser } = useContext(AppContext)
  const { openAlert } = useAlertContext()

  const setDocumentListener = () => {
    documentDispatch({ prevPath: documentState.path })
    const unsubscribe = db.doc(documentState.path).onSnapshot(
      snapshot => {
        if (snapshot.exists) {
          const data = snapshot.data()

          const id = snapshot.id
          const doc = { ...data, id }
          documentDispatch({
            doc,
            ref: snapshot.ref,
            loading: false,
          })
        } else {
          documentDispatch({
            loading: false,
          })
        }
      },
      error => {
        console.log('documentState', documentState)
        console.error(error)

        if (documentState.disableErrorAlerts) return

        if ((error as any).code === 'permission-denied') {
          openAlert({
            title: 'You don’t have permissions to view this data',
            message: (
              <>
                Please send a screenshot of this page to the #tech-request
                channel on Slack.
                <br />
                <br />
                Signed in as: {currentUser?.email}
                <br />
                Path: <code>{documentState.path}</code>
              </>
            ),
          })
        }
      }
    )
    documentDispatch({ unsubscribe })
  }
  useEffect(() => {
    const { path, prevPath, unsubscribe } = documentState
    if (path && path !== prevPath) {
      if (unsubscribe) unsubscribe()
      setDocumentListener()
    }
  }, [documentState])
  useEffect(
    () => () => {
      if (documentState.unsubscribe) documentState.unsubscribe()
    },
    []
  )

  const updateDoc = data =>
    db.doc(documentState.path).set(
      {
        ...data,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedBy: currentUser?.uid ?? '',
        _ft_updatedAt: new Date(),
        _ft_updatedBy: currentUser ? firetableUser(currentUser) : null,
      },
      { merge: true }
    )

  return [documentState, documentDispatch, updateDoc]
}

export default useDoc
