import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { useAppContext } from './AppProvider'
import { getRouteLabel } from './routes'

export default function SegmentPageTracker() {
  const history = useHistory()
  const { navItems } = useAppContext()

  const trackPage = (pathname: string) => {
    window.analytics.page(getRouteLabel(navItems, pathname) || pathname, {
      path: pathname,
    })
  }

  useEffect(() => {
    trackPage(history.location.pathname)
    return history.listen(location => trackPage(location.pathname))
  }, [history, navItems])

  return null
}
