import React, { useState } from 'react'
import { AlertContext } from 'contexts/AlertContext'

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core'
import { Modal } from '@antlerengineering/components'

export default function AlertProvider({
  children,
}: React.PropsWithChildren<{}>) {
  const [open, setOpen] = useState(false)
  const [title, setTitle] = useState<React.ReactNode>(null)
  const [message, setMessage] = useState<React.ReactNode>(null)
  const [body, setBody] = useState<React.ReactNode>(null)

  const openAlert = ({
    title,
    message,
    body,
  }: {
    title: React.ReactNode
    message?: React.ReactNode
    body?: React.ReactNode
  }) => {
    setTitle(title)
    setMessage(message ?? null)
    setBody(body ?? null)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setTitle(null)
    setMessage(null)
    setBody(null)
  }

  return (
    <AlertContext.Provider value={{ openAlert }}>
      {children}

      {open && (
        <Modal
          onClose={handleClose}
          title={title}
          body={
            <>
              {message && (
                <DialogContentText style={{ whiteSpace: 'pre-line' }}>
                  {message}
                </DialogContentText>
              )}

              {body}
            </>
          }
          actions={{
            primary: {
              children: 'OK',
              onClick: handleClose,
              autoFocus: true,
            },
          }}
        />
      )}
    </AlertContext.Provider>
  )
}
