import React, { lazy, Suspense, useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import smartlookClient from 'smartlook-client'
import posthog from 'posthog-js';

import {
  CssBaseline,
  MuiThemeProvider as ThemeProvider,
} from '@material-ui/core'
import Theme from './Theme'

import { AppProvider, useAppContext } from './utils/AppProvider'
import { FiregridProvider } from './contexts/FiregridContext'
import CustomBrowserRouter from './utils/CustomBrowserRouter'
import SegmentPageTracker from 'utils/SegmentPageTracker'
import PrivateRoute from './utils/PrivateRoute'
import { SnackProvider } from './utils/SnackProvider'
import AlertProvider from './utils/AlertProvider'
import GlobalStyles from './utils/GlobalStyles'
import { routes } from './constants/routes'

import {
  ErrorBoundary,
  Loading,
  EmptyState,
} from '@antlerengineering/components'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import Navigation from './components/Navigation'
import Snack from './components/Snack'

import SignOutView from './views/AuthViews/SignOutView'
import ForgotPasswordView from 'views/AuthViews/ForgotPasswordView'
//import ResetPasswordView from 'views/AuthViews/ResetPasswordView'
import AuthLinkView from 'views/AuthViews/AuthLinkView'
import GoogleAuthView from 'views/AuthViews/GoogleAuthView'
import SignInView from 'views/AuthViews/SignInView'
//import SignUpView from 'views/AuthViews/SignUpView'

const AuthTokenView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "TokenView" */
    './views/TokenView'
  )
)
const AdminAuthView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "AdminAuthView" */
    './views/AuthViews/AdminAuthView'
  )
)
const ProfileView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "ProfileView" */
    './views/ProfileView'
  )
)
const AdvisorProfileView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "AdvisorProfileView" */
    './views/AdvisorProfileView'
  )
)
const PortfolioView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "PortfolioView" */
    './views/PortfolioViews/PortfolioView'
  )
)
const BasicPortfolioView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "PortfolioView" */
    './views/PortfolioViews/BasicPortfolioView'
  )
)
const FoundersView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "FoundersView" */
    './views/CohortViews/FoundersView'
  )
)
const TeamsView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "TeamsView" */
    './views/CohortViews/TeamsView'
  )
)
const CoachingMyTeamsView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "CoachingMyTeamsView" */
    './views/CoachingViews/MyTeamsView'
  )
)
const CoachingArchiveView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "CoachingArchiveView" */
    './views/CoachingViews/ArchiveView'
  )
)
const EmployeesView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "EmployeesView" */
    './views/EmployeesView'
  )
)
const GlobalEventsView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "EventsViews/GlobalEventsView" */
    './views/GlobalEventsView'
  )
)
const InvestorsView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "EventsViews/InvestorsView" */
    './views/InvestmentViews/InvestorsView'
  )
)
const InvestorsFirmsView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "EventsViews/InvestorsFirmsView" */
    './views/InvestmentViews/FirmsView'
  )
)
const InvestorsFirmView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "EventsViews/InvestorsFirmView" */
    './views/InvestmentViews/FirmView'
  )
)

const TalentNetworkView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "TalentNetworkView" */
    './views/TalentNetworkView'
  )
)

const LibraryView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "ResourceViews/LibraryView" */
    './views/ResourceViews/LibraryView'
  )
)
const ProgramResourcesView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "ResourceViews/ProgramResourcesView" */
    './views/ResourceViews/ProgramResourcesView'
  )
)
const TechResourcesView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "ResourceViews/TechResourcesView" */
    './views/ResourceViews/TechResourcesView'
  )
)

const PartnershipsView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "PartnershipsView" */
    './views/PartnershipsView'
  )
)
const AdvisorsView = lazy(() =>
  import(
    /* webpackPrefetch: true */
    /* webpackChunkName: "AdvisorsView" */
    './views/AdvisorsView'
  )
)

const PortfolioWrapper = () => {
  const { algoliaKeys, userClaims } = useAppContext()
  if (Object.keys(algoliaKeys).length === 0 || !userClaims) return <Loading />

  if (Object.keys(algoliaKeys).includes('portfolio-fusion'))
    return <Redirect to={routes.myPortfolio} />

  return <Redirect to={routes.globalPortfolio} />
}

//const App = () => {
const App: React.FC = () => {
  useEffect(() => {
    smartlookClient.init('smartlook-key')
    posthog.init("phc_z12oxx9EzDLiOPGYBHpsIkGa00VjdIpgxkkjkeFMIo5", {api_host: "https://app.posthog.com"});
  }, [])

  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <GlobalStyles />
      <ErrorBoundary>
        <SnackProvider>
          <AlertProvider>
            <AppProvider>
              <FiregridProvider>
                <DndProvider backend={HTML5Backend} context={window}>
                  <CustomBrowserRouter>
                    <SegmentPageTracker />
                    <Suspense fallback={<Loading fullScreen />}>
                      <Switch>
                        <Route
                          exact
                          path={routes.tokenAuth}
                          render={() => <AuthTokenView />}
                        />
                        <Route
                          exact
                          path={routes.adminAuth}
                          render={() => <AdminAuthView />}
                        />
                        <Route
                          exact
                          path={routes.authLink}
                          render={() => <AuthLinkView />}
                        />
                        <Route
                          exact
                          path={routes.googleAuth}
                          render={() => <GoogleAuthView />}
                        />
                        <Route
                          exact
                          path={routes.forgotPassword}
                          render={() => <ForgotPasswordView />}
                        />
                        {/* <Route
                  exact
                  path={routes.resetPassword}
                  render={() => <ResetPasswordView />}
                /> */}
                        <Route
                          exact
                          path={routes.signIn}
                          render={() => <SignInView googleAuth passwordAuth />}
                        />
                        {/* <Route
                  exact
                  path={routes.signUp}
                  render={() => <SignUpView passwordAuth />}
                /> */}

                        <Route
                          exact
                          path={routes.signOut}
                          render={() => <SignOutView />}
                        />

                        <PrivateRoute
                          exact
                          path={routes.profile}
                          render={() => (
                            <Navigation>
                              <ProfileView />
                            </Navigation>
                          )}
                        />
                        <PrivateRoute
                          exact
                          path={routes.advisorProfile}
                          render={() => (
                            <Navigation>
                              <AdvisorProfileView />
                            </Navigation>
                          )}
                        />

                        <PrivateRoute
                          exact
                          path={routes.home}
                          render={() => <Redirect to={routes.portfolio} />}
                        />

                        <PrivateRoute
                          exact
                          path={routes.portfolio}
                          render={() => (
                            <Navigation>
                              <PortfolioWrapper />
                            </Navigation>
                          )}
                        />
                        <PrivateRoute
                          exact
                          path={routes.myPortfolio}
                          render={() => (
                            <Navigation>
                              <PortfolioView />
                            </Navigation>
                          )}
                        />
                        <PrivateRoute
                          exact
                          path={routes.globalPortfolio}
                          render={() => (
                            <Navigation>
                              <BasicPortfolioView />
                            </Navigation>
                          )}
                        />

                        <PrivateRoute
                          exact
                          path={routes.founders}
                          render={() => (
                            <Navigation>
                              <FoundersView />
                            </Navigation>
                          )}
                        />
                        <PrivateRoute
                          exact
                          path={routes.teams}
                          render={() => (
                            <Navigation>
                              <TeamsView />
                            </Navigation>
                          )}
                        />
                        <PrivateRoute
                          exact
                          path={routes.employees}
                          render={() => (
                            <Navigation>
                              <EmployeesView />
                            </Navigation>
                          )}
                        />

                        <PrivateRoute
                          exact
                          path={routes.coaching}
                          render={() => (
                            <Redirect to={routes.coachingMyTeams} />
                          )}
                        />
                        <PrivateRoute
                          exact
                          path={routes.coachingMyTeams}
                          render={() => (
                            <Navigation>
                              <CoachingMyTeamsView />
                            </Navigation>
                          )}
                        />
                        <PrivateRoute
                          exact
                          path={routes.coachingArchive}
                          render={() => (
                            <Navigation>
                              <CoachingArchiveView />
                            </Navigation>
                          )}
                        />

                        <PrivateRoute
                          exact
                          path={routes.eventsGlobal}
                          render={() => (
                            <Navigation>
                              <GlobalEventsView />
                            </Navigation>
                          )}
                        />

                        <PrivateRoute
                          exact
                          path={routes.investment}
                          render={() => (
                            <Redirect to={routes.investmentInvestors} />
                          )}
                        />
                        <PrivateRoute
                          exact
                          path={routes.investmentInvestors}
                          render={() => (
                            <Navigation>
                              <InvestorsView />
                            </Navigation>
                          )}
                        />
                        <PrivateRoute
                          exact
                          path={routes.investmentFirms}
                          render={() => (
                            <Navigation>
                              <InvestorsFirmsView />
                            </Navigation>
                          )}
                        />
                        <PrivateRoute
                          exact
                          path={routes.investmentFirms + '/:id'}
                          render={props => (
                            <Navigation>
                              <InvestorsFirmView {...props} />
                            </Navigation>
                          )}
                        />

                        <PrivateRoute
                          exact
                          path={routes.talentNetwork}
                          render={() => (
                            <Navigation>
                              <TalentNetworkView />
                            </Navigation>
                          )}
                        />

                        <PrivateRoute
                          exact
                          path={routes.resources}
                          render={() => (
                            <Redirect to={routes.resourcesProgram} />
                          )}
                        />
                        <PrivateRoute
                          exact
                          path={routes.resourcesLibrary}
                          render={() => (
                            <Navigation>
                              <LibraryView />
                            </Navigation>
                          )}
                        />
                        <PrivateRoute
                          exact
                          path={routes.resourcesProgram}
                          render={() => (
                            <Navigation>
                              <ProgramResourcesView />
                            </Navigation>
                          )}
                        />
                        <PrivateRoute
                          exact
                          path={routes.resourcesTech}
                          render={() => (
                            <Navigation>
                              <TechResourcesView />
                            </Navigation>
                          )}
                        />

                        <PrivateRoute
                          exact
                          path={routes.partnerships}
                          render={() => (
                            <Navigation>
                              <PartnershipsView />
                            </Navigation>
                          )}
                        />

                        <PrivateRoute
                          exact
                          path={routes.advisors}
                          render={() => (
                            <Redirect to={routes.advisorsExperts} />
                          )}
                        />
                        <PrivateRoute
                          exact
                          path={routes.advisorsExperts}
                          render={() => (
                            <Navigation>
                              <AdvisorsView
                                filters={[
                                  { label: 'Type', facet: 'type' },
                                  {
                                    label: 'Experience (Industry)',
                                    facet: 'expert',
                                  },
                                  { label: 'Location', facet: 'location' },
                                ]}
                                requiredFilters={
                                  'startupExpert:true AND showOnHub:true'
                                }
                                key={routes.advisorsExperts}
                              />
                            </Navigation>
                          )}
                        />
                        <PrivateRoute
                          exact
                          path={routes.advisorsIndustryConnectors}
                          render={() => (
                            <Navigation>
                              <AdvisorsView
                                key={routes.advisorsIndustryConnectors}
                                requiredFilters={'showOnHub:true'}
                              />
                            </Navigation>
                          )}
                        />
                        <PrivateRoute
                          exact
                          path={routes.advisorsVenturePartners}
                          render={() => (
                            <Navigation>
                              <AdvisorsView
                                filters={[
                                  {
                                    label: 'Experience (Industry)',
                                    facet: 'expert',
                                  },
                                  { label: 'Location', facet: 'location' },
                                ]}
                                requiredFilters={
                                  'type:"Venture Partner" AND showOnHub:true'
                                }
                                key={routes.advisorsVenturePartners}
                              />
                            </Navigation>
                          )}
                        />

                        {/* <PrivateRoute
                      exact
                      path={routes.settings}
                      render={() => <Navigation> TODO:settings</Navigation>}
                    /> */}

                        <PrivateRoute
                          render={() => (
                            <Navigation>
                              <EmptyState message="Page Not Found" />
                            </Navigation>
                          )}
                        />
                      </Switch>
                    </Suspense>
                    <Snack />
                  </CustomBrowserRouter>
                </DndProvider>
              </FiregridProvider>
            </AppProvider>
          </AlertProvider>
        </SnackProvider>
      </ErrorBoundary>
    </ThemeProvider>
  )
}

export default App
