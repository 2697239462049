import React from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'

import {
  makeStyles,
  createStyles,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  Button,
  ListItemAvatar,
  ListItemText,
} from '@material-ui/core'
import grey from '@material-ui/core/colors/grey'

import Logo from 'assets/fusion-32h.svg'
import NavSidebarItem from './NavSidebarItem'
import { Thumbnail, SIDEBAR_WIDTH } from '@antlerengineering/components'
import { routes } from '../../constants/routes'
import { useAppContext } from '../../utils/AppProvider'

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      width: SIDEBAR_WIDTH,
      overflowX: 'hidden',
    },
    paper: {
      boxSizing: 'content-box',
      paddingLeft: 'env(safe-area-inset-left)',
    },
    paperAnchorDockedLeft: { borderRight: 0 },

    grid: { height: `calc(100% + ${theme.spacing(1.5)}px)` },

    logoListItem: {
      ...theme.mixins.toolbar,

      backgroundColor: theme.palette.background.paper,
      position: 'sticky',
      top: 0,
      zIndex: 1,

      '&:hover': { backgroundColor: grey[100] },
    },

    navList: {
      paddingTop: theme.spacing('xs'),
    },

    profilePhoto: {
      width: 24,
      height: 24,
    },

    signOut: {
      margin: theme.spacing(-2, 0, 0, -1),
    },
  })
)

export interface INavSidebarProps {
  sidebarCollapsed: boolean
  open: boolean
  onCloseDrawer: () => void
}

export default function NavSidebar({
  sidebarCollapsed,
  open,
  onCloseDrawer,
}: INavSidebarProps) {
  const classes = useStyles()

  const { profileDocState, navItems } = useAppContext()
  const user = profileDocState.doc

  return (
    <Drawer
      variant={sidebarCollapsed ? 'temporary' : 'persistent'}
      open={!sidebarCollapsed || open}
      onClose={onCloseDrawer}
      classes={{
        root: classes.root,
        paper: clsx(classes.root, classes.paper),
        paperAnchorDockedLeft: classes.paperAnchorDockedLeft,
      }}
      ModalProps={{ keepMounted: true }}
      PaperProps={{ elevation: 8 }}
    >
      <Grid
        container
        justify="space-between"
        direction="column"
        className={classes.grid}
        wrap="nowrap"
        spacing={3}
      >
        <Grid item xs component="nav" aria-label="main navigation">
          <ListItem
            button
            component={Link}
            to="/"
            classes={{ root: classes.logoListItem }}
          >
            <ListItemIcon>
              <></>
            </ListItemIcon>
            <img src={Logo} alt="Antler Fusion" width="106" height="32" />
          </ListItem>

          <List className={classes.navList}>
            {navItems.map(item => (
              <NavSidebarItem key={item.route} item={item} />
            ))}
          </List>
        </Grid>

        {/* Spacer */}
        <Grid item xs />

        <Grid item>
          <ListItem>
            <ListItemAvatar>
              {!!user?.profilePhoto?.[0]?.downloadURL ? (
                <Thumbnail
                  imageUrl={user?.profilePhoto?.[0]?.downloadURL}
                  shape="circle"
                  size="100x100"
                  className={classes.profilePhoto}
                />
              ) : (
                <span />
              )}
            </ListItemAvatar>

            <ListItemText
              primary={
                user &&
                `${user?.preferredName || user?.firstName} ${user?.lastName}`
              }
              primaryTypographyProps={{
                variant: 'body1',
              }}
            />
          </ListItem>

          <ListItem>
            <ListItemAvatar>
              <span />
            </ListItemAvatar>

            <Button
              color="primary"
              component={Link}
              to={routes.signOut}
              className={classes.signOut}
            >
              Sign Out
            </Button>
          </ListItem>
        </Grid>
      </Grid>
    </Drawer>
  )
}
