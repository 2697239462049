import React from 'react'

import { makeStyles, createStyles } from '@material-ui/core'

export const useGlobalStyles = makeStyles(theme =>
  createStyles({
    '@global': {
      // Overrides <CSSBaseline />
      html: {
        // Use subpixel antialiasing
        WebkitFontSmoothing: 'subpixel-antialiased !important',
        MozOsxFontSmoothing: 'auto !important',
      },

      a: {
        color: theme.palette.primary.main,
      },
    },
  })
)

const GlobalStyles: React.FunctionComponent = () => {
  useGlobalStyles()
  return null
}

export default GlobalStyles
